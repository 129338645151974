/* PARCEIROS WELCOME TEMPLATE 04 */

/*#homepartners_04 {
    background-color: var(-gray-light);
    padding-top: 3rem;
    padding-bottom: 3rem;
}*/

#homepartners_04 .card-body{
    text-align: center;
    background-color: var(--gray-light);
}

#homepartners_04 .card:hover .partner-title {
    color: var(--primary);
}
