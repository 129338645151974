/* MOTE TEMPLATE 03 */

/*.page-03 #mote.wave-animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}*/

#homemote_03 {
    background-color: var(--bs-gray-900);
    padding-top: 3rem;
    padding-bottom: 3rem;
}
#homemote_03 .text-columns {
    color: var(--white);
    column-count: 2;
    column-gap: 30px;
}

@media only screen and (max-width: 767px) {
     #homemote_03 .text-columns {
        text-align: center;
        column-count: 1;
    }

}