/* SECÇÕES TEMPLATE 04 */
.page-04 .welcome > section:not(#slider) {
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.page-04 .welcome > section:not(#slider):nth-child(odd) {
    position: relative;
    padding: 160px 0;

    &:before {
        content: "";
        background-color: var(--bs-gray-200);
        position: absolute;
        bottom: 60px;
        top: 60px;
        left: 0;
        right: 0;
        transform: skewY(-3deg);
    }
}

/* TÍTULOS TEMPLATE 04 */
.page-04 .title-properties {
    text-align: left;
}

.page-04 .title-properties .titulo-pp {
    margin-bottom: 15px;
    padding-bottom: 10px;
    position: relative;
}

.page-04 .title-properties .titulo-pp:after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: var(--primary);
    left: 0;
    right: 0;
    bottom: 0;
}
.page-04 .title-properties .subtitulo-pp{
    position: relative;
    font-size: 1.2rem;
    color: var(--bs-secondary-color);
    text-transform: unset;
}

