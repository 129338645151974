/* SECONDARY PAGES */

.normal .div-banner img,
.div-card .div-banner img,
.list .div-banner img,
.list-alt .div-banner img,
.tabs .div-banner img,
.accordion .div-banner img {
    max-height: 50vh;
    width: 100%;
    object-fit: cover;
    object-position: 85% 50%;
}

main.list-left .content-list-left .div-image,
main.list-right .content-list-right .div-image,
main.list-alt .content-list-alt .div-image,
main.service-detail .div-image,
main.product-detail .div-image { 
    margin-bottom: 25px;
}

@media screen and (min-width: 768px) {
    main.list-left .content-list-left .div-image,
    main.list-alt .content-list-alt .list-left.div-image,
    main.service-detail .div-image,
    main.product-detail .div-image {
        width: 30%;
        float: left;
        margin-right: 25px;
    }
    main.list-right .content-list-right .div-image,
    main.list-alt .content-list-alt .list-right.div-image {
        width: 30%;
        float: right;
        margin-left: 25px;
    }
}

/* ACCORDION PAGE */

.element{
    float: right;
    justify-content: center;
    shape-margin: 7em;
}

/*.accordion .content-body .div-image {
    max-height: 400px;
    overflow: hidden;
}*/

main.accordion .accordion-item {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.08);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    margin-bottom: 0.5rem;
}

/*main.accordion .accordion-header {
    padding: 1.25rem 1.25rem;
    background-color: var(--gray-light);
    border-bottom: 0px;
}*/

main.accordion .accordion-button {
    background-color: rgba(0, 0, 0, 0.03);
    column-gap: 10px;
}

main.accordion .accordion-title {
    font-size: 1.3rem;
    font-weight: 700;
    color: var(--body);
    text-transform: uppercase;
}

main.accordion .accordion-header:hover .accordion-title {
    color: var(--secondary);
}

main.accordion .accordion-body .div-image { 
    margin-bottom: 25px;
}

@media screen and (min-width: 768px) {
    main.accordion .accordion-body .div-image {
        width: 30%;
        float: right;
        margin-left: 25px;
    }
}

/*main.accordion .card-header button .bi-chevron-down {
    color: var(--primary);
    font-size: 1.5rem;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}*/

/*main.accordion .card-header button[aria-expanded="true"] .bi-chevron-down {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}*/


/* END ACCORDION PAGE */

/* TABS/PILLS PAGE */

main.tabs .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: var(--white);
    background-color: var(--primary);
}

main.tabs .nav-pills .nav-link {
    color: var(--body)
}

main.tabs .tab-content .div-image { 
    margin-bottom: 25px;
}

@media screen and (min-width: 768px) {
    main.tabs .tab-content .div-image {
        width: 30%;
        float: left;
        margin-right: 25px;
    }
}

/* END TABS/PILLS PAGE */

/* NEWS/ARTICLE DETAIL & LATEST NEWS */
    #article-detail .article-title {
        color: var(--primary);
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.25;
    }

    /*#article-detail .article-abstract {}*/

    #article-detail .article-categories {
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
    }

    #article-detail .article-source {
        font-size: .75rem;
    }

    #article-detail .article-source span:not(:last-child):after {
        padding: 0 .5rem;
        color: var(--primary);
        content: "|";
    }

    /*@media only screen and (min-width: 767px) {
        #latest-news {
            position: -webkit-sticky;
            position: sticky;
            top: 145px;
            right: 0;
            z-index: 2;
            height: 100%;
            overflow-y: hidden;
            -webkit-transition: padding .3s cubic-bezier(.694,.0482,.335,1) 0s;
            transition: padding .3s cubic-bezier(.694,.0482,.335,1) 0s;
        }
    }*/

    #latest-news .card {
        overflow: hidden;
        transition: 0.2s ease-out;
    }

    #latest-news .card:hover {
        transform: translate3d(0, -2px, 0);
    }

    #latest-news .card a {
        color: var(--body);
    }

    #latest-news  .timestamp {
        font-size: 80%;
        margin-bottom: 0.5rem;
    }

    #latest-news .subtitulo-pp {
        font-weight: 700;
        font-size: 1.1rem;
        text-align: center;
    }

    #latest-news  .card-title {
        font-weight: 600;
        font-size: 0.875rem;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

/* END NEWS/ARTICLE DETAIL */

/* GALLERY DETAIL */

#gallery .masonry-layout {
    column-count: 4; /* Adjust the number of columns based on your design */
    column-gap: 1rem; /* Space between columns */
    width: 100%; /* Full width of the container */
}

#gallery .masonry-item {
    break-inside: avoid; /* Prevent items from splitting across columns */
    margin-bottom: 1rem; /* Space between items */
    position: relative;
    border-radius: 5px;
    overflow: hidden;
}

#gallery .masonry-item img {
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
#gallery .masonry-item:hover img {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

#gallery .hover-container {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: scale(0);
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-backface-visibility: hidden;
    -moz-webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
 }

 #gallery .masonry-item:hover .hover-container {
    opacity: 0.5;
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
 }

 #gallery .masonry-item .hover-container .icon { 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    -webkit-transition: opacity 0.2s 0s ease-in-out;
    -moz-transition: opacity 0.2s 0s ease-in-out;
    -o-transition: opacity 0.2s 0s ease-in-out;
    -ms-transition: opacity 0.2s 0s ease-in-out;
    transition: opacity 0.2s 0s ease-in-out;
    -moz-transition-delay: 0.2s;
    -webkit-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    -ms-transition-delay: 0.2s;
    transition-delay: 0.2s;
 }

 #gallery .masonry-item .hover-container .icon i { 
    font-size: 3rem;
    color: var(--white);
 }

 #gallery .masonry-item:hover .hover-container .icon {
    opacity: 1;
 }

 #gallery .masonry-item .image-container {
    position: relative;
    overflow: hidden;
 }

 #gallery .masonry-item .image-caption {
    font-size: .875em;
    color: var(--bs-secondary-color);
    text-align: center;
    padding: 1rem;
 }

@media only screen and (max-width: 992px) {
    #gallery .masonry-layout {
        column-count: 3;
    }
}

@media only screen and (max-width: 767px) {
    #gallery .masonry-layout {
        column-count: 2;
    }
}

/* END GALLERY DETAIL  */


/* PRESS/CLIPPING PAGE */

    .press-date {
        font-size: 0.875rem;
        margin: 0.5rem 0;
    }

    .press-title {
        font-weight: bold;
        margin-bottom: 1rem;
    }

    .press-abstract {
        /*display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;*/
        margin-bottom: 1rem;
    }

    .press-text {
        margin-bottom: 1rem;
    }

/* END PRESS/CLIPPING PAGE */

/* MENU (RESTAURANT) */

.menu-cards .nav-tabs {
    border-bottom: none;
    justify-content: center;
    gap: 40px;
    margin-bottom: 4rem;

}

@media (max-width: 767px){
    .menu-cards .nav-tabs {
        flex-direction: column;
        align-items: center;
    }
}

.menu-cards .nav-tabs > li.nav-item > .nav-link {
    font-size: 1.2rem;
    border: none;
    font-weight: 600;
    color: var(--bs-gray-600);
    border-bottom: 2px solid transparent;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
}

.menu-cards .nav-tabs > li.nav-item > .nav-link.active,
.menu-cards .nav-tabs > li.nav-item > .nav-link:hover {
    color: var(--bs-gray-700);
    border-bottom: 2px solid var(--primary);
}

.menu-cards .tab-content > .tab-pane {

    .col-lg-6:nth-child(odd) .menu-flex-align-items {
        padding-right: 25px;
    }
    .col-lg-6:nth-child(even) .menu-flex-align-items {
        padding-left: 25px;
    }
    .menu-flex-align-items {
        display: flex;
        gap: 30px;
        /*padding-left: 40px;
        justify-content: center;
        align-items: center;*/

        .item-image img {
            border-radius: 50%;
        }

        .item-content {
            flex-grow: 1;

            .item-name-price {
                display: flex;
                align-items: center;
                font-weight: 600;
                 font-size: 1.2rem;
                /*width: 100%;*/

                .item-divider {
                    flex-grow: 1;
                    border-top: 1px dashed;
                    border-color: var(--bs-gray-300);
                    margin: 0 20px;
                }

                .item-price{
                   color: var(--secondary);
                }
            }

            .item-text {
                font-size: 0.875rem;
                margin-top: 0.5rem;
            }
        }
    }

    @media (max-width: 767px){
        .col-lg-6:nth-child(odd) .menu-flex-align-items {
            padding-right: 5px;
        }
        .col-lg-6:nth-child(even) .menu-flex-align-items {
            padding-left: 5px;
        }
        .menu-flex-align-items {
            flex-direction: column;
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}

/* END MENU (RESTAURANT) */

/*@media (max-width: 576px){
    .normal .content-body .div-image {
        height: auto;
    }
}*/

/* CARDS (CONTENT) */

#brand-cards .card,
#gallery-cards .card,
#partner-cards .card,
#product-cards .card,
#service-cards .card {
    /*transition: .5s;
    border: unset;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    cursor: default;
    border-radius: 0;*/

    border: 1px solid rgba(0,0,0,.05);
    box-shadow: 0 1px 2px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.08);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    overflow: hidden;
}

#brand-cards .card:hover,
#gallery-cards .card:hover,
#partner-cards .card:hover,
#product-cards .card:hover,
#service-cards .card:hover {
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.08);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
}

.accommodation-cards .card-bar::before,
.routes-cards .card-bar::before,
.restaurant-cards .card-bar::before,
.event-cards .card-bar::before,
.novidades-cards .card-bar::before,
.animation-cards .card-bar::before {
    content: "";
    display: block;
    width: 50px;
    height: 10px;
    border-radius: 5px;
    background: var(--orange);
    position: absolute;
}
.accommodation-cards .card-bar::before {
    background: rgb(94, 52, 38);
}
.routes-cards .card-bar::before {
    background: rgb(64, 141, 212);
}
.restaurant-cards .card-bar::before {
    background: rgb(200, 99, 32);
}
.event-cards .card-bar::before {
    background: rgb(111, 84, 170);
}
.novidade-cards .card-bar::before {
    background: rgb(158, 200, 58);
}
.animation-cards .card-bar::before {
    background: rgb(234, 180, 46);
}


.accommodation-cards a:hover,
.routes-cards a:hover,
.restaurant-cards a:hover,
.event-cards a:hover,
.novidade-cards a:hover,
.animation-cards a:hover {
    text-decoration: none;
}

.accommodation-cards .card-title,
.routes-cards .card-title,
.restaurant-cards .card-title,
.event-cards .card-title,
.novidade-cards .card-title,
.animation-cards .card-title {
    color: var(--body);
    font-size: 1.728rem;
    line-height: normal;
}

.accommodation-cards .card-text,
.routes-cards .card-text,
.restaurant-cards .card-text,
.event-cards .card-text,
.novidade-cards .card-text,
.animation-cards .card-text {
    color: var(--body);
    font-size: 16px;
}

/* END CARDS */