 /* TÍTULOS TEMPLATE 02 */
    .page-02 .title-properties {
        text-align: left;
    }

    .page-02 .titulo-pp {
        font-size: 1.2rem;
        padding: 0;
        line-height: 1px;
        /*margin: 0;*/
        letter-spacing: 1.5px;
        color: color-mix(in srgb, var(--primary), transparent 50%);
        position: relative;
    }
    
    .page-02 .titulo-pp:after {
        content: "";
        width: 8%;
        height: 1px;
        display: inline-block;
        background: var(--primary);
        margin: 4px 10px;
    }

    .page-02 .subtitulo-pp {
        font-size: 1.8rem;
        color: var(--secondary);
    }

/* MOTE TEMPLATE 02 */
    /*.page-02 #mote.wave-animated {
        animation: up-down 2s ease-in-out infinite alternate-reverse both;
    }*/

    /*@keyframes up-down {
        0% {
        transform: translateY(10px);
        }
    
        100% {
        transform: translateY(-10px);
        }
    }*/

