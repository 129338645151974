 /* WAVES ANIMATION BEFORE FOOTER */
.page-01 .hero-waves-footer,
.page-03 .hero-waves-footer,
.page-04 .hero-waves-footer,
.page-05 .hero-waves-footer {
    display: none;
}
.page-02 .hero-waves-footer {
    display: block;
    width: 100%;
    height: 60px;
    position: relative;
}
  
.page-02 .hero-waves-footer .wave1 use {
    animation: move-forever1 10s linear infinite;
    animation-delay: -2s;
    fill: var(--secondary);
    opacity: 0.6;
}
  
.page-02 .hero-waves-footer .wave2 use {
    animation: move-forever2 8s linear infinite;
    animation-delay: -2s;
    fill: var(--secondary);
    opacity: 0.4;
}

.page-02  .hero-waves-footer .wave3 use {
    animation: move-forever3 6s linear infinite;
    animation-delay: -2s;
    fill: var(--secondary);
}

@keyframes move-forever1 {
    0% {
        transform: translate(85px, 0%);
    }

    100% {
        transform: translate(-90px, 0%);
    }
}

@keyframes move-forever2 {
    0% {
        transform: translate(-90px, 0%);
    }

    100% {
        transform: translate(85px, 0%);
    }
}

@keyframes move-forever3 {
    0% {
        transform: translate(-90px, 0%);
    }

    100% {
        transform: translate(85px, 0%);
    }
}

 /* END WAVES ANIMATION BEFORE  FOOTER */

/* STATIC BANNER SHAPE TEMPLATE 04 */
.page-01 footer .static-banner-shape-footer,
.page-02 footer .static-banner-shape-footer,
.page-03 footer .static-banner-shape-footer,
.page-05 footer .static-banner-shape-footer {
    display: none;
}
.page-04 footer{
    position: relative;
}
.page-04 footer .static-banner-shape-footer {
    position: absolute;
    top: -1px;
    z-index: 1;
    width: 100%;
    transform: rotate(-180deg);
}

.page-04 footer .static-banner-shape-footer img {
    max-width: none;
    width: 100%;
}

.page-04 footer .container {
    padding-top: 60px;
}

/* END STATIC BANNER SHAPE TEMPLATE 04 */

footer {
	background-color: var(--secondary);
    color: var(--white);
	font-size: 0.875rem;
	padding-top: 3rem;
	/*margin-top: 170px;*/
    /*width: 100%;
    bottom: 0;
	position: absolute;*/
	
}
footer a {
	color: var(--white);
}

footer a:hover {
	color: var(--primary);
}

footer h5 {
	font-weight: 700;
	font-size: 1rem;
	color: var(--white);
}
footer h6 {
    font-size: 0.875rem;
}

footer .social-media i {
    font-size: 1.5rem;
}

footer .current_bottom{
	color: var(--primary);
    text-decoration: underline;
}

/* footer .social-media img:hover,
footer .social-media i:hover {
	filter: drop-shadow(0px 0px 20px var(--primary));
} */

/*footer span.logosfooter {
	display: flex;
	align-items: flex-start;
	justify-content: space-evenly;
}*/
footer .copyright-info {
    padding: 0.75rem 0;
	font-size: 12px;
	text-align: center;
    background-color: color-mix(in srgb, var(--secondary), white 5%);
}