/* PRODUTOS TEMPLATE 03 */
#homeproducts_03 {
    background-color: var(--bs-gray-300);
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#homeproducts_03 .hover-product {
    position: relative;
    overflow: hidden;
}

#homeproducts_03 .hover-product::after {
    content: '';
    width: 100%;
    height: 15px;
    background: var(--secondary);
    position: absolute;
    bottom: -15px;
    left: 0;
    display: block;
    transition: all 0.3s;
    z-index: 998;
}

#homeproducts_03 .hover-product .hover-product-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 90;
    transition: all 0.4s;
}
#homeproducts_03 .hover-product .hover-product-content {
    position: absolute;
    bottom: 1.2rem;
    left: 0;
    transition: all 0.4s;
    padding: 1rem;
    z-index: 99;
    opacity: 0;
}

#homeproducts_03 .hover-product .hover-product-content .product-title { 
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--white);
}

@media only screen and (min-width: 992px) {
    #homeproducts_03 .hover-product:hover .hover-product-overlay{
        background: rgba(var(--primary-rgb), 0.8);
    }
    #homeproducts_03 .hover-product:hover .hover-product-content {
        opacity: 1;
    }
    #homeproducts_03 .hover-product:hover::after {
        bottom: 0;
    }
}

@media (max-width: 992px) {
    #homeproducts_03 .hover-product .hover-product-content {
        background: rgba(var(--primary-rgb), 0.8);
        opacity: 1;
    }
    #homeproducts_03 .hover-product .hover-product-content .product-title { 
        font-size: 0.875rem;
    }
}

/* PRODUTOS */