.page-03 #footer_02 { 
    background-color: var(--black);
}

#footer_02 .legal-info h6 {
    margin-bottom: 0;
}

#footer_02 .footer-contacts {
    z-index: 2;
}

#footer_02 .legal-info .slash {
    font-size: 0.75rem;
}

#footer_02 .flex-align-items {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 50px;
    justify-content: center;
}

#footer_02 .flex-align-sub-items {
    display: flex;
    gap: 15px;
    /*flex-direction: column;*/
    justify-content: center;
    align-items: center;
}

#footer_02 .flex-align-sub-items i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    color: var(--white);
    padding: 10px;
    border-radius: 50%;
    /*border-radius: calc(infinity * 1px);*/
    border: 2px dotted color-mix(in srgb, var(--primary), transparent 40%);
}

.page-03 #footer_02 .copyright-info{ 
    background-color: color-mix(in srgb, var(--black), white 5%);
}

/*#footer_02 .copyright-info{
    background-color: color-mix(in srgb, var(--secondary), white 5%);
}*/