
#header_03 .navbar-toggler {
    display: block !important;
    /*background-color: var(--primary)*/
}

/* quando o menu é sempre fixed em todas as resoluções */
#header_03 #sideNavigationMenu.show {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
}

#header_03 #sideNavigationMenu.show ul.navbar-nav.social-media li:nth-child(1) {
    transition-delay: 2.2s;
}
