/* SERVIÇOS TEMPLATE 04 */

/*#homeservices_04 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}*/

#homeservices_04 .service-container-layout-4 {
    display: flex;
    position: relative;
    padding-top: 40px;

    .service-image {
        overflow: hidden;
        width: 20%;
        margin-right: 5%;
        img {
            border-radius: 0.375rem;
            transform: scale(1);
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
        }
    }
    .service-content-layout-4 {
        position: relative;
        width: 70%;

        .service-title {
            color: var(--primary);
            font-size: 1.1rem;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1.35;
            transition: ease-in-out 0.3s;
            margin-bottom: 10px;
        }
        .service-description {
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 4;
            line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;

            p {
                color: var(--body);
                font-size: 0.875rem;
                margin-bottom: 0;
            }
        }
        &:before {
            position: absolute;
            content: "";
            height: 40px;
            width: 40px;
            background:  color-mix(in srgb, var(--primary), transparent 70%);
            border-radius: 50px;
            z-index: 1;
            bottom: -15px;
            left: -15px;
            transition: 0.3s;
        }
    }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 2px;
        background: color-mix(in srgb, var(--body), transparent 90%);
    }
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 2px;
        background: var(--primary);
        border-right: 10px solid var(--white);
    }

    &:hover {
        .service-content-layout-4 .service-title {
            color: var(--secondary);
        }
    }
}