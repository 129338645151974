/* SERVIÇOS WELCOME TEMPLATE 03 */

#homeservices_03 {
    /*background: color-mix(in srgb, var(--bs-black), transparent 10%);*/
    background-color: var(--bs-gray-300);
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#homeservices_03 .service-container-layout-3 {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        inset: 0;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 25%, rgba(0, 0, 0, 0.9) 75%);
        z-index: 2;
    }

    .service-image {
        overflow: hidden;
        position: relative;
    }
    .service-content-layout-3 {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 3;
        padding: 30px;

        .service-title {
            color: var(--white);
            font-size: 1.2rem;
            font-weight: 700;
            /*text-transform: uppercase;*/
            line-height: 1.35;
            transition: ease-in-out 0.3s;
            margin-bottom: 10px;
            padding-left: 10px;
            border-left: 3px solid var(--primary);
        }

        .description-container{
            display: grid;
            grid-template-rows: 0fr;
            transition: 0.5s ease-in-out;
            visibility: hidden;
            opacity: 0;

            .service-description {
                display: -webkit-box;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;

                p {
                    /*overflow: hidden;*/
                    color: var(--white);
                    font-size: 0.875rem;
                    margin-bottom: 0;
                }
            }
        }
    }
    &:hover {
        .service-content-layout-3 .description-container {
            grid-template-rows: 1fr;
            visibility: visible;
            opacity: 1;
        }
    }
}