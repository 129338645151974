/* Ativar se for página com fundo de cor primária em todo o site */
/*body {
    background-color: var(--primary);
}
.page-05 main a {
	color: var(--secondary);
}
.page-05 main a:hover {
    color: rgba(var(--bs-dark-rgb), 0.5);
}

.page-05 .btn-primary {
    background-color: rgba(var(--bs-dark-rgb), 0.5);
    border: 1px solid rgba(var(--bs-dark-rgb), 0.5);
    color: var(--white);
}

.page-05 .btn-primary:hover { 
    color: var(--white);
}*/

/* desactivar se for página com fundo de cor primária em todo o site */
.page-05 .welcome {
    background-color: var(--primary);
}

.page-05 .welcome > section:not(#slider):nth-child(odd) {
    background-color: var(--bs-gray-200);
}
/* */

.page-05 .subtitulo-pp {
    color: var(--black);
}

.page-05 .btn-expand {
    color: var(--white);
}
.page-05 .welcome > section:not(#slider):nth-child(odd) .btn-expand {
    color: var(--body);
}
.page-05 .welcome > section:not(#slider):nth-child(odd) .btn-expand:hover {
    color: var(--white);
}
.btn-expand:before {
    background: rgba(var(--bs-dark-rgb), 0.5);
}
.btn-expand:hover:before {
    background: rgba(var(--bs-dark-rgb), 0.8);
}

/* desactivar se for página com fundo de cor primária em todo o site */
.page-05 .welcome > section:not(#slider):nth-child(even) .btn-primary {
    background: rgba(var(--bs-dark-rgb), 0.5);
}
.page-05 .welcome > section:not(#slider):nth-child(even) .btn-primary:hover {
    background: rgba(var(--bs-dark-rgb), 0.8);
}