/*.page-01 .bg-navbar {
    background-color: var(--primary);
}

@media (max-width: 992px) { 
    .page-01 .bg-navbar {
        background-color: var(--white);
    }
}*/

#header_01 .svg-logo {
    width: 240px;
}

@media (min-width: 992px) {
    #header_01 #navbar .navbar-expand-lg .navbar-nav > .nav-item {
        margin: 0 0.5rem;
    
        &:first-child { 
            margin-left: 0;
        }
        &:last-child { 
            margin-right: 0;
        }
    }
    #header_01 #navbar .navbar-expand-lg .navbar-nav .nav-item a,
    #header_01 #navbar .navbar-extra .nav-item a {
        color: var(--bs-gray-700);
    }

    #header_01 #navbar .navbar-expand-lg .navbar-nav > .nav-item > a:hover,
    #header_01 #navbar .navbar-expand-lg .navbar-nav > .nav-item > a.current_top,
    #header_01 #navbar .navbar-expand-lg .dropdown-menu a:hover,
    #header_01 #navbar .navbar-expand-lg .dropdown-menu a.current_top {
        color: var(--bs-gray-900);
        text-decoration: underline;
    }
}
