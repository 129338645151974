/* PARCEIROS WELCOME TEMPLATE 02 */
#homepartners_02 {
    background-color: var(--bs-gray-200);
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-bottom: 200px;

    .swiper-partners {
        margin-bottom: -200px;
    }
}

#homepartners_02 .card-body {
    text-align: center;
    background-color: var(--gray-light);
}

#homepartners_02 .card:hover .partner-title {
    color: var(--primary);
}