#header_05 {
    background-color: var(--secondary);
}

#header_05 .bg-navbar {
    background-color: var(--secondary);
}

/* se o cabeçalho tiver banner em vez do logo */
/*#header_05 .banner-header {
    width: 100%;
    height: 100%;
    max-height: 150px;
    max-width: 1300px;
}*/

#header_05 .svg-logo {
    width: 240px;
}

@media (max-width: 992px) { 
    #header_05 .bg-navbar {
        background-color: var(--secondary);
    }
    #header_05  .animated-icon3 span {
        background: var(--white);
    }
}
@media (max-width: 576px) { 
    #header_05 .svg-logo {
        width: 180px;
    }
}

@media (min-width: 992px) {
    #header_05 #navbar .navbar-expand-lg .navbar-nav > .nav-item {
        margin: 0 0.5rem;
    
        &:first-child { 
            margin-left: 0;
        }
        &:last-child { 
            margin-right: 0;
        }
    }
    #header_05 #navbar .navbar-expand-lg .navbar-nav .nav-item a,
    #header_05 #navbar .navbar-extra .nav-item a {
        color: var(--white);
    }

    #header_05 #navbar .navbar-expand-lg .navbar-nav > .nav-item > a:hover,
    #header_05 #navbar .navbar-expand-lg .navbar-nav > .nav-item > a.current_top,
    #header_05 #navbar .navbar-expand-lg .dropdown-menu a:hover,
    #header_05 #navbar .navbar-expand-lg .dropdown-menu a.current_top {
        color: var(--primary);
        text-decoration: underline;
    }
}
