/* ESTATÍSTICAS TEMPLATE 03 */

#homenumbers_03 { 
    background-color: var(--black);
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#homenumbers_03 .card-body {
    display: inline-flex;
    align-items: center;
    gap: 30px;
    background-color: var(--white);
    border-radius: 0.375rem;

    .statistics-icon {
        color: var(--secondary);
        font-size: 2.5rem;
    }

    .statistics-number {
        color: var(--primary);
        font-weight: bold;
        font-size: 3rem;
        line-height: 1;
    }

    .statistics-label {
        text-transform: uppercase;
        color: var(--body);
    }

    @media only screen and (max-width: 767px) {
        flex-direction: column;
        gap: 0;
        text-align: center;

        .statistics-label {
            font-size: 0.875rem;
        }
    }
}