/* ESTATÍSTICAS TEMPLATE 01 */

#homenumbers_01 {
    /*background-color: var(--gray-light);*/
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#homenumbers_01 .card-body {
    text-align: center;
}
#homenumbers_01 .card-body .statistics-icon {
    color: var(--primary);
    font-size: 2.5rem;
}
#homenumbers_01 .card-body .statistics-number {
    color: var(--secondary);
    font-weight: bold;
    font-size: 3rem;
}
#homenumbers_01 .card-body .statistics-label {
    text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
    #homenumbers_01 .card-body .statistics-label {
        font-size: 0.875rem;
    }
}

/* END ESTATÍSTICAS */
