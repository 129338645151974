/* SERVIÇOS LANDING PAGE TEMPLATE 01*/

#homeservices_02 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#homeservices_02 .service-container-layout-2 {
    .service-image {
        border-radius: 0.375rem;
        overflow: hidden;
        img {
            transform: scale(1);
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
        }
    }
    .service-content-layout-2 {
        background: color-mix(in srgb, var(--gray-light), transparent 5%);
        padding: 50px 30px;
        margin: -100px 30px 0 30px;
        transition: all ease-in-out 0.3s;
        position: relative;
        text-align: center;
        border-radius: 0.375rem;
        border-bottom: 4px solid transparent;
        box-shadow: 0 1px 2px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.08);
        /*box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.08);*/

        .service-title {
            color: var(--primary);
            font-size: 1.1rem;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1.35;
            transition: ease-in-out 0.3s;
            margin-bottom: 10px;
        }
        .service-description {
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 4;
            line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;

            p {
                color: var(--body);
                font-size: 0.875rem;
                margin-bottom: 0;
            }
        }
    }
    &:hover {
        .service-image img {
            transform: scale(1.2);
        }
        .service-content-layout-2 {
            border-bottom-color: var(--secondary);
        }
        .service-content-layout-2 .service-title {
            color: var(--secondary);
        }
    }
}