/* APP FORM */
#app_form .control-label{
    padding: 0 10px;
    position: absolute;
    top: -6px;
    background-color: white;
    left: 30px;
    font-size: 0.7rem;
    font-weight: 700;
    z-index: 100;
}
.custom-file-label {
    width: 98%;
    border-radius: 0;
    border-color: var(----yellow);
    cursor: pointer;
    left: 15px;
}
.custom-file-label:after {
    content: "Carregar";
    border-radius: 0;
    border-color: var(--yellow);
    color: #FFFFFF;
    background-color: var(--yellow);
    cursor: pointer;
}

#app_form button.remove-file{
    border: 2px solid var(--body);
    border-radius: 50%;
    background-color: white;
    font-size: 11px;
}

/* Recuperação de password */

    @media (min-width: 992px) {
        #recovery { 
            /* 100 vh - (navbar + footer) */
            min-height: calc(100vh - 438px);
        }
    }

/* End Recuperação de password */


