/* PRODUTOS WELCOME TEMPLATE 04 */

/*#homeproducts_04 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}*/

#homeproducts_04 .hover-product {
    position: relative;
    overflow: hidden;

    .product-image {
        img {
            -ms-transform: scale(1);
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1);
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
        }
    
        &:hover{
            img {
                -ms-transform: scale(1.1);
                -webkit-transform: scale(1.1);
                -moz-transform: scale(1.1);
                -o-transform: scale(1.1);
                transform: scale(1.1);
                -webkit-transition: all .3s ease-in-out;
                -moz-transition: all .3s ease-in-out;
                -o-transition: all .3s ease-in-out;
                transition: all .3s ease-in-out;
            }
        }
    }

    .hover-product-content {
        position: absolute;
        bottom: 1.2rem;
        left: 0;
        transition: all 0.4s;
        padding: 1rem;
        z-index: 99;
        background: rgba(var(--primary-rgb), 0.8);
        opacity: 1;

        .product-title { 
            font-size: 1.2rem;
            font-weight: 600;
            color: var(--white);
        }
    }
}

@media (max-width: 992px) {
    #homeproducts_04 .hover-product .hover-product-content .product-title { 
        font-size: 0.875rem;
    }
}
/* END PRODUTOS */