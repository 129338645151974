@media (min-width: 992px) {
    #header_02 #navbar .navbar-expand-lg .navbar-nav > .nav-item {
        margin: 0 0.5rem;
    
        &:first-child { 
            margin-left: 0;
        }
        &:last-child { 
            margin-right: 0;
        }
    }
    #header_02 #navbar .navbar-expand-lg .navbar-nav .nav-item a,
    #header_02 #navbar .navbar-extra .nav-item a {
        color: var(--secondary);
    }

    #header_02 #navbar .navbar-expand-lg .navbar-nav > .nav-item > a:hover,
    #header_02 #navbar .navbar-expand-lg .navbar-nav > .nav-item > a.current_top,
    #header_02 #navbar .navbar-expand-lg .dropdown-menu a:hover,
    #header_02 #navbar .navbar-expand-lg .dropdown-menu a.current_top {
        color: var(--primary);
        text-decoration: underline;
    }
}