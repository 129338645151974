/* SWIPER */

    /*.swiper,
    .swiper-container {
        width: 100%;
        height: 100%;
    }*/

    /*#slider .swiper-slide {
        -webkit-transition: 0.3s all;
        transition: 0.3s all;
    }*/

    .page-02 #slider .swiper-slide {
        background-color: var(--secondary);
        background-image: unset !important;
    }


    .page-03 #slider .swiper-slide:before {
        content: "";
        background: color-mix(in srgb, var(--bs-black), transparent 50%);
        position: absolute;
        inset: 0;
        z-index: 0;
        pointer-events: none;
    }

    .page-04 #slider .swiper-slide:before {
        content: "";
        background: color-mix(in srgb, var(--bs-black), transparent 70%);
        position: absolute;
        inset: 0;
        z-index: 2;
    }

    .swiper-button-next{
        /*background-image: none;
        right: -20px;
        left: auto;*/
        right: 30px;
        outline: none;
        color: var(--white) !important;
    }
    .swiper-button-prev {
        /*background-image: none;
        left: -20px;
        right: auto;*/
        left: 30px;
        outline: none;
        color: var(--white) !important;
    }

    .swiper-pagination-bullet {
        width: 1rem !important;
        height: 1rem !important;
        background: var(--white) !important;
        opacity: .7 !important;
    }

    .swiper-pagination-bullet-active {
        background: var(--primary) !important;
        opacity: 1 !important;
        outline: none;
    }

    /* TESTEMUNHOS */
        /*#opinion .swiper-pagination-bullet-active{
            background-color: var(--body) !important;
            margin: 0 5px;
        }
        #opinion .swiper-pagination-bullet{
            margin: 0 2px;
        }*/

    /* end TESTEMUNHOS */

    .swiper-pagination-cartaz {
        position: absolute;
        text-align: center;
        transition: .2s opacity;
        transform: translate3d(0,0,0);
        z-index: 10;
    }

    .page-04 #slider .swiper-pagination-cartaz {
        display: none;
    }

    /*#slider .swiper-slide{
        height: calc(50vh);
    }*/
    /*@media screen and (max-width: 767px) {
        #slider .swiper-slide{
            height: calc(80vh - 70px);
        }
    }*/

    #slider .swiper-button-next{
        top: 0;
        right: 0;
        height: 100%;
        width: 8%;
        margin-top: 0;
    }

    #slider .swiper-button-prev{
        top: 0;
        left: 0;
        height: 100%;
        width: 8%;
        margin-top: 0;
    }

    #slider .swiper-button-prev:after,
    #slider .swiper-button-next:after {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .swiper-button-next:hover, .swiper-button-prev:hover {
        color: var(--primary) !important;
    }

/* END SWIPER */

/*CARTAZ*/
    .caption_top_center {
        position: absolute;
        top: 25%;
        right: 50%;
        left: 50%;
        width: 75%;
        text-align: center;
        padding: 0;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .caption_top_left {
        position: absolute;
        top: 25%;
        left: 0;
        width: 50%;
        text-align: left;
        -webkit-transform: translate(25%, -50%);
        -moz-transform: translate(25%, -50%);
        -ms-transform: translate(25%, -50%);
        -o-transform: translate(25%, -50%);
        transform: translate(25%, -50%);
    }

    .caption_top_right {
        position: absolute;
        top: 25%;
        left: 0%;
        width: 50%;
        text-align: right;
        -webkit-transform: translate(75%, -50%);
        -moz-transform: translate(75%, -50%);
        -ms-transform: translate(75%, -50%);
        -o-transform: translate(75%, -50%);
        transform: translate(75%, -50%);
    }

    .caption_bottom_left {
        position: absolute;
        /*top: 50%;*/
        bottom: 0;
        left: 0;
        width: 50%;
        text-align: left;
        -webkit-transform: translate(25%, -50%);
        -moz-transform: translate(25%, -50%);
        -ms-transform: translate(25%, -50%);
        -o-transform: translate(25%, -50%);
        transform: translate(25%, -50%);
    }

    .caption_bottom_right {
        position: absolute;
        bottom: 0;
        left: 0%;
        width: 50%;
        text-align: right;
        -webkit-transform: translate(75%, -50%);
        -moz-transform: translate(75%, -50%);
        -ms-transform: translate(75%, -50%);
        -o-transform: translate(75%, -50%);
        transform: translate(75%, -50%);
    }

    .caption_bottom_center {
        position: absolute;
        right: auto;
        bottom: 0;
        left: 50%;
        width: 75%;
        text-align: center;
        padding: 0;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .caption_middle_left {
        position: absolute;
        top: 50%;
        bottom: auto;
        left: 0%;
        width: 50%;
        text-align: left;
        -webkit-transform: translate(25%, -50%);
        -moz-transform: translate(25%, -50%);
        -ms-transform: translate(25%, -50%);
        -o-transform: translate(25%, -50%);
        transform: translate(25%, -50%);
    }

    .caption_middle_right {
        position: absolute;
        top: 50%;
        bottom: auto;
        left: 0%;
        width: 50%;
        text-align: right;
        -webkit-transform: translate(75%, -50%);
        -moz-transform: translate(75%, -50%);
        -ms-transform: translate(75%, -50%);
        -o-transform: translate(75%, -50%);
        transform: translate(75%, -50%);
    }

    .caption_middle_center {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        width: 75%;
        text-align: center;
        padding: 0;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    /*.slide {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        text-align: center;
        font-size: 18px;
        background: #fff;
        overflow: hidden;
    }*/

    /*.swiper-pagination{
        bottom: 0;
        right: 0;
        left: 0;
    }*/

    .slide-image {
        /*position: absolute;*/
        right: 0;
        height: 100%;
        background-position: 50% 50%;

        /*width: 110% !important;
        left: -5% !important;
        border-bottom-left-radius: 35%;
        border-bottom-right-radius: 35%;*/
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        will-change: transform;
    }

    .slide-caption {
        z-index: 3;
        /*font-size: 3vw;
        font-weight: 900;
        line-height: 1.3;
        color: var(--white);
        max-width: 50%;
        white-space: normal;
        word-break: break-word;
        text-transform: none;
        text-shadow: rgb(0 0 0 / 40%) 1px 1px 1px;
        z-index: 100;*/
    }

    .cartaz-titulo, .cartaz-subtitulo {
        color: var(--white);
        font-size: 3.25rem;
        text-transform: uppercase;
        font-weight: 800;
        line-height: 1.2;
        margin: 0 0 1rem;
        /* text-shadow: 1px 2px 2px rgb(51 51 51 / 50%), 0px 0px 40px rgb(0 0 0 / 10%); */
        /* text-shadow: 0 .3rem .9rem rgba(0, 0, 0, .4); --- Pedro Martins */
        text-shadow: 0 0.2rem 0.45rem rgb(0 0 0 / 50%);
        z-index: auto;
        padding: 0px;
        text-wrap: balance;
    }

    .cartaz-subtitulo {
        font-size: 1.5rem;
        text-transform: initial;
        font-weight: 600;
        /*line-height: 1.1;
        text-shadow: 1px 2px 2px rgb(51 51 51 / 50%), 0px 0px 20px rgb(0 0 0 / 10%);*/
        text-shadow: 0 0.2rem 0.3rem rgb(0 0 0 / 65%);
    }

    /* Animation delays */

    .swiper-cartaz .swiper-slide-active h2,
    .swiper-cartaz .swiper-slide-active h3{
		animation-name: fadeIn;
		animation-duration: 1s;
		animation-fill-mode: both;
	}

    .swiper-cartaz .swiper-slide-active h2 {
		-webkit-animation-delay: 1s;
		-moz-webkit-animation-delay: 1s;
		animation-delay: 1s;
	}

	.swiper-cartaz .swiper-slide-active h3 {
		-webkit-animation-delay: 1.5s;
		animation-delay: 1.5s;
	}

	/*.swiper-cartaz .swiper-slide-active h3:first-child {
		-webkit-animation-delay: 2s;
		animation-delay: 2s;
	}

	.swiper-cartaz .swiper-slide-active h3:nth-child(2) {
		-webkit-animation-delay: 3s;
		animation-delay: 3s;
	}*/

    .swiper-cartaz .swiper-slide-active a {
		animation-name: fadeIn;
		animation-duration: 2.5s;
		-webkit-animation-delay: 2.5s;
		animation-delay: 2.5s;
		animation-fill-mode: both;
	}
    /* END Animation delays */

    .btn-cartaz {
        font-size: 0.875rem;
        font-weight: 500;
        color: var(--white) !important;
        border: 1px solid var(--secondary) !important;
        padding: 0.5rem 1.5rem;
        border-radius: 30px;
        margin-top: 25px;
        background-color: var(--secondary);
        /*background-color: rgb(255 255 255 / 10%) !important;
        border-color: var(--white) !important;
        -webkit-box-shadow: 0 0 0 10px transparent !important;
        -moz-box-shadow: 0 0 0 10px transparent !important;
        box-shadow: 0 0 0 10px transparent !important;*/
    }
    .btn-cartaz:hover {
        color: var(--white) !important;
        border: 1px solid var(--primary) !important;
        background-color: var(--primary);
        /*background-color: rgb(255 255 255 / 100%) !important;*/
    }
    
    .page-02 .btn-cartaz {
        border: 1px solid var(--primary) !important;
        background-color: var(--primary);
    }

    .page-02 .btn-cartaz:hover {
        border: 1px solid var(--white) !important;
        background-color: var(--secondary);
    }

    @media screen and (max-width: 992px) {
        .cartaz-titulo {
            font-size: 2rem;
            line-height: 1;
        }
        .cartaz-subtitulo {
            font-size: 1.75vh;
        }
    }
    @media screen and (max-width: 767px) {
        /*.cartaz-titulo {
            font-size: 3vh;
        }*/

        .caption_top_center,
        .caption_top_left,
        .caption_top_right,
        .caption_middle_center,
        .caption_middle_left,
        .caption_middle_right,
        .caption_bottom_center,
        .caption_bottom_left,
        .caption_bottom_right {
            text-align: center;
            position: absolute;
            top: 50%;
            right: auto;
            bottom: auto;
            left: 50%;
            width: 75%;
            text-align: center;
            padding: 0;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }

        /*.swiper-button-next,
        .swiper-button-prev { 
            display: none !important;;
        }*/
    }

    /* BUTTON SCROLL SLIDER */
    .demo {
        position: absolute;
        bottom: 20px;
        left: 50%;
        z-index: 2;
        display: inline-block;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        color: var(--cinza);
        text-decoration: none;
        transition: opacity .3s;
    }
    #slider-btn {
        padding-top: 60px;
    }
    #slider-btn span {
        position: absolute;
        top: 0;
        left: 50%;
        width: 30px;
        height: 50px;
        margin-left: -15px;
        border: 2px solid var(--white);
        border-radius: 50px;
        box-sizing: border-box;
    }
    #slider-btn span::before {
        position: absolute;
        top: 10px;
        left: 50%;
        content: '';
        width: 6px;
        height: 6px;
        margin-left: -3px;
        background-color: var(--white);
        border-radius: 100%;
        -webkit-animation: sdb10 2s infinite;
        animation: sdb10 2s infinite;
        box-sizing: border-box;
    }

    @-webkit-keyframes sdb10 {
        0% {
            -webkit-transform: translate(0, 0);
            opacity: 0;
        }
        40% {
            opacity: 1;
        }
        80% {
            -webkit-transform: translate(0, 20px);
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }
    @keyframes sdb10 {
        0% {
            transform: translate(0, 0);
            opacity: 0;
        }
        40% {
            opacity: 1;
        }
        80% {
            transform: translate(0, 20px);
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    /* WAVES SVG */
    .page-01 .hero-waves-slider,
    .page-03 .hero-waves-slider,
    .page-04 .hero-waves-slider,
    .page-05 .hero-waves-slider {
        display: none;
    }
    .page-02 #slider .hero-waves-slider {
        display: block;
        width: 100%;
        height: 60px;
        position: relative;
        background-color: var(--secondary);
        margin-top: -1px;
    }
      
    .page-02 #slider .hero-waves-slider .wave1 use {
        animation: move-forever1 10s linear infinite;
        animation-delay: -2s;
        fill: var(--white);
        opacity: 0.6;
    }
      
    .page-02 #slider .hero-waves-slider .wave2 use {
        animation: move-forever2 8s linear infinite;
        animation-delay: -2s;
        fill: var(--white);
        opacity: 0.4;
    }
    
    .page-02 #slider .hero-waves-slider .wave3 use {
        animation: move-forever3 6s linear infinite;
        animation-delay: -2s;
        fill: var(--white);
    }
    
    @keyframes move-forever1 {
        0% {
            transform: translate(85px, 0%);
        }
    
        100% {
            transform: translate(-90px, 0%);
        }
    }
    
    @keyframes move-forever2 {
        0% {
            transform: translate(-90px, 0%);
        }
    
        100% {
            transform: translate(85px, 0%);
        }
    }
    
    @keyframes move-forever3 {
        0% {
            transform: translate(-90px, 0%);
        }
    
        100% {
            transform: translate(85px, 0%);
        }
    }

    /* STATIC BANNER SHAPE TEMPLATE 04 */
    .page-01 #slider .static-banner-shape,
    .page-02 #slider .static-banner-shape,
    .page-03 #slider .static-banner-shape,
    .page-05 #slider .static-banner-shape {
        display: none;
    }
    .page-04 #slider {
        position: relative;
    }
    .page-04 #slider .static-banner-shape {
        position: absolute;
        bottom: -1px;
        z-index: 1;
        width: 100%;
    }

    .page-04 #slider .static-banner-shape img {
        max-width: none;
        width: 100%;
    }

/*end CARTAZ*/