/* GALERIA WELCOME TEMPLATE 03 */

#homegallery_03 {
    background-color: var(--black);
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#homegallery_03 .gallery-item {
    position: relative;
    overflow: hidden;
    border-radius: 0.375rem;

    img {
		-ms-transform: scale(1);
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1);
		-webkit-transition: all .3s ease-in-out;
		-moz-transition: all .3s ease-in-out;
		-o-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}
	&:hover {
        img {
            -ms-transform: scale(1.1);
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -o-transform: scale(1.1);
            transform: scale(1.1);
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
        }
	}
}