/* NOTÍCIAS TEMPLATE 04 */

/*#homenews_04 {
    background-color: var(--white);
    padding-top: 3rem;
    padding-bottom: 3rem;
}*/

#homenews_04 .article-container{
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 0.375rem;
    overflow: hidden;

    .article-image {
        overflow: hidden;
        position: relative;
        img {
            transform: scale(1);
            transition: all .3s ease-in-out;
        }
    }

    .timestamp {
        position: absolute;
        bottom: 0;
        right: 0;
        background: var(--primary);
        padding: 8px;
        font-size: 10px;
        color: var(--white);
    }

    .article-body {
        background-color: var(--bs-gray-400);
        padding: 15px;

        /*.timestamp {
            font-size: 0.75rem;
            color: var(--bs-secondary-color);
            margin-bottom: 0.5rem;
            position: relative;
        }*/
        
        .article-title {
            color: var(--secondary);
            font-size: 1.1rem;
            font-weight: 700;
            margin-bottom: 0.75rem;
            line-height: 1.35;
            transition: 0.3s;
        }
        .article-text {
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 4;
            line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            color: var(--body);
            font-size: 0.875rem;
        }
        .article-text p{
            color: var(--body);
            font-size: 0.875rem;
            margin-bottom: 0;
        }
        .article-link {
            font-size: 0.875rem;
            text-align: right;
            text-decoration: none;
            font-weight: bold;
            color: var(--primary);
        }
    }

    &:hover {
        .article-image img {
            transform: scale(1.1);
        }
        .article-body {
            .article-title {
                color: var(--primary);
            }
            /*.article-link { 
                color: var(--primary);
            }*/
        }

    }
}