/* ESTATÍSTICAS TEMPLATE 04 */

#homenumbers_04 {
    /*background-color: var(-gray-light);
    padding-top: 3rem;
    padding-bottom: 3rem;*/

    .card-body {
        text-align: center;
    
        .statistics-icon {
            color: var(--white);
        
            i { 
                display: inline-flex;
                justify-content: center;
                align-items: center;
    
                font-size: 1.8rem;
                background-color: var(--primary);
                padding: 10px;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                /*border-radius: calc(infinity * 1px);*/
                box-shadow: var(--bs-box-shadow);
            }
        }
    
        .statistics-number {
            color: var(--secondary);
            font-weight: bold;
            font-size: 2rem;
            margin-top: 10px;
        }
    
        .statistics-label {
            text-transform: uppercase;
        }
        
    }
}

@media only screen and (max-width: 767px) {
    #homenumbers_04 .card-body .statistics-label {
        font-size: 0.875rem;
    }
}

/* END ESTATÍSTICAS */