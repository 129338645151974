/* GALERIA WELCOME TEMPLATE 01 */

#homegallery_01 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#homegallery_01 .masonry-layout {
    column-count: 4; /* Adjust the number of columns based on your design */
    column-gap: 1rem; /* Space between columns */
    width: 100%; /* Full width of the container */
}

#homegallery_01 .masonry-item {
    break-inside: avoid; /* Prevent items from splitting across columns */
    margin-bottom: 1rem; /* Space between items */
    position: relative;
    border-radius: 5px;
    overflow: hidden;
}

#homegallery_01 .masonry-item img {
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
#homegallery_01 .masonry-item:hover img {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

#homegallery_01 .hover-container {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: scale(0);
    background-color: rgba(0, 0, 0, 0.6);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
 }

 #homegallery_01 .masonry-item:hover .hover-container {
    opacity: 0.5;
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
 }

 #homegallery_01 .masonry-item .hover-container .icon { 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    -webkit-transition: opacity 0.2s 0s ease-in-out;
    -moz-transition: opacity 0.2s 0s ease-in-out;
    -o-transition: opacity 0.2s 0s ease-in-out;
    -ms-transition: opacity 0.2s 0s ease-in-out;
    transition: opacity 0.2s 0s ease-in-out;
    -moz-transition-delay: 0.2s;
    -webkit-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    -ms-transition-delay: 0.2s;
    transition-delay: 0.2s;
 }

 #homegallery_01 .masonry-item .hover-container .icon i { 
    font-size: 3rem;
    color: var(--white);
 }

 #homegallery_01 .masonry-item:hover .hover-container .icon {
    opacity: 1;
 }

@media only screen and (max-width: 992px) {
    #homegallery_01 .masonry-layout {
        column-count: 3;
    }
}

@media only screen and (max-width: 767px) {
    #homegallery_01 .masonry-layout {
        column-count: 2;
    }
}

/* END GALERIA WELCOME */
