#header_04 .svg-logo {
    width: 240px;
}

@media (max-width: 992px) { 
    #header_04 {
        background-color: var(--white);
    }

}

@media (min-width: 992px) {

    #header_04 #navbar .navbar-expand-lg .navbar-nav > .nav-item {
        margin: 0 0.5rem;
    
        /*&:first-child { 
            margin-left: 0;
        }
        &:last-child { 
            margin-right: 0;
        }*/
    }
    #header_04 #navbar .navbar-expand-lg .navbar-nav .nav-item a,
    #header_04 #navbar .navbar-extra .nav-item a {
        color: var(--secondary);
    }

    #header_04 #navbar .navbar-expand-lg .navbar-nav > .nav-item > a:hover,
    #header_04 #navbar .navbar-expand-lg .navbar-nav > .nav-item > a.current_top,
    #header_04 #navbar .navbar-expand-lg .dropdown-menu a:hover,
    #header_04 #navbar .navbar-expand-lg .dropdown-menu a.current_top {
        color: var(--primary);
        /* text-decoration: underline; */
    }

    #header_04.fixed-top .nav-effect{
        /*-webkit-transition: background 1.5s;
        -moz-transition: background 1.5s;
        -ms-transition: background 1.5s;
        -o-transition: background 1.5s;
        transition: background 1.5s;*/
        padding: 20px;
        background: transparent;
        box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        -ms-transition: all 0.5s;
        transition: all 0.5s;
    }
    #header_04.is-scrolling .nav-effect{
        background: rgba(255, 255 , 255, 1);
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 2px;
        padding: 0;
    }

    #header_04 .navbar-container {
        background-color: var(--white);
        border-radius: 10px;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        -ms-transition: all 0.5s;
        transition: all 0.5s;
    }
}