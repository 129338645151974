/* PRODUTOS TEMPLATE 02 */

#homeproducts_02{
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#homeproducts_02 .card{
    /*transition: .5s;
    border: unset;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    cursor: default;
    border-radius: 0;*/

    border: 1px solid rgba(0,0,0,.05);
    box-shadow: 0 1px 2px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.08);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    overflow: hidden;
    padding: 1rem;
    background-color: var(--gray-light);
}
#homeproducts_02 .card:hover {
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
    box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.08);
}
#homeproducts_02 .product-image {
    overflow: hidden;
}
#homeproducts_02 .product-image img {
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
#homeproducts_02 .product-image:hover img {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
/*#homeproducts_02 .card-body {
    background-color: var(--gray-light);
}*/
#homeproducts_02 .card-title {
    color: var(--primary);
    font-size: 1.1rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.35;
}
#homeproducts_02 .card-text p {
    font-size: 0.875rem;
    margin-bottom: 0;
}

#homeproducts_02 .card-text {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.5;
}

/* PRODUTOS */