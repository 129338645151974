
/* BREADCRUMB (desativado)*/
    ul.breadcrumb {
        padding: 10px 16px;
        list-style: none;
        background-color: #eee;
    }
    ul.breadcrumb li {
        display: inline;
        font-size: 18px;
    }
    ul.breadcrumb li+li:before {
        padding: 8px;
        color: black;
        content: "/\00a0";
    }
    ul.breadcrumb li a {
        color: #0275d8;
        text-decoration: none;
    }
    ul.breadcrumb li a:hover {
        color: #01447e;
        text-decoration: underline;
    }
/* end BREADCRUMB */

/* BOOTSTRAP BREADCRUMB*/

.breadcrumb-container {
    background-color: var(--gray-light);
    /* -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1); */
}

.breadcrumb-container .breadcrumb {
    background-color: var(--gray-light);
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: 500;
}

/* end BOOTSTRAP BREADCRUMB*/