
/* SERVIÇOS WELCOME */

#homeservices_01 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#homeservices_01 .service-container {
    border-radius: 0.375rem;
    overflow: hidden;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
#homeservices_01 .service-container:hover {
    transform: translate3d(0, -10px, 0);
    box-shadow: 0px 15px 20px rgba(var(--primary-rgb), 0.4);
    /*box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);*/
}
#homeservices_01 .service-content {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: var(--white);
    padding: 2.5rem;
    background-color: var(--primary);
}

.page-05 .welcome > section:not(#slider):nth-child(even)#homeservices_01 .service-content {
    background-color: rgba(var(--bs-dark-rgb), 0.8);
}
.page-05 .welcome > section:not(#slider):nth-child(even)#homeservices_01 .service-container:hover {
    box-shadow: 0px 15px 20px rgba(var(--bs-dark-rgb), 0.4);
}

@media only screen and (max-width: 767px) { 
    #homeservices_01 .service-content {
        padding: 1rem;
        font-size: 0.875rem;
    }
}

/* END SERVIÇOS */
