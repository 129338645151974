/* ESTATÍSTICAS TEMPLATE 02 */

#homenumbers_02 {
    background-color: var(--gray-light);
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#homenumbers_02 .card-body {
    text-align: center;

    .statistics-icon {
        color: var(--primary);
        font-size: 2.5rem;

        i { 
            position: absolute;
            font-size: 1.8rem;
            padding: 5px;
            top: -10px;
            width: 50px;
            border-radius: 50%;
            /*border-radius: calc(infinity * 1px);
            box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);*/
            box-shadow: var(--bs-box-shadow) !important;
            transform: translate(-50%, -50%);
        }
    }
    .statistics-number{ 
        color: var(--secondary);
        font-weight: bold;
        font-size: 3rem;
        margin-top: 10px;
    }

    .statistics-label {
        text-transform: uppercase;
    }
}

@media only screen and (max-width: 767px) {
    #homenumbers_02 .card-body .statistics-label {
        font-size: 0.875rem;
    }
}