/* BOTÕES TEMPLATE 03 */

.page-03 .btn-primary, .page-03 .btn-cartaz { 
    border-radius: 0;
}

.page-03 #homenews_03 .btn-expand {
    color: var(--white);
}
 
 /* TÍTULOS TEMPLATE 02 */

.page-03 .title-properties .titulo-pp{
    position: relative;
    text-transform: unset;
    color: var(--body);
}

.page-03 #homemote_03 .title-properties .titulo-pp,
.page-03 #homenews_03 .title-properties .titulo-pp,
.page-03 #homenumbers_03 .title-properties .titulo-pp,
.page-03 #homegallery_03 .title-properties .titulo-pp {
    color: var(--white);
}

.page-03 .title-properties .subtitulo-pp{
    font-size: 1.2rem;
    color: var(--secondary);
    text-transform: unset;
}

.page-03 #homemote_03 .title-properties .subtitulo-pp,
.page-03 #homenews_03 .title-properties .subtitulo-pp,
.page-03 #homenumbers_03 .title-properties .subtitulo-pp,
.page-03 #homegallery_03 .title-properties .subtitulo-pp {
    color: rgba(240, 238, 255, 0.6);
}

.page-03 .title-properties .titulo-pp:before,
.page-03 .title-properties .titulo-pp:after {
    content: "";
    width: 50px;
    height: 2px;
    background: var(--primary);
    display: inline-block;
}

.page-03 .title-properties .titulo-pp:before {
    margin: 0 15px 10px 0;
    }

.page-03 .title-properties .titulo-pp:after {
    margin: 0 0 10px 15px;
}
